import React from "react"
import "../stylesheets/general.css"
import { Layout, Content } from "../components"
import { H1 } from "../elements"

export const PageNotFound = () => {
  return (
    <Layout>
      <Content>
        <H1 large>Uh-oh... What you're looking for couldn't be found</H1>
      </Content>
    </Layout>
  )
}

export default PageNotFound
